export class LangReportUniversal {
  title: string = null;
  table: string[] = [];
  constructor(private idLanguage: number, private isValue: boolean) {
    this.selectLanguage();
  }

  selectLanguage() {
    switch (this.idLanguage) {
      case 1:
        this.title = this.isValue ? "Reporte valores universales" : "Reporte características universales";
        this.table = [
          "Reporte valores universales" // 0
          , "Reporte características universales" // 1
          , "No." // 2
          , "Característica" // 3
          , "Valor" // 4
          , "Tipo" // 5
        ] 
        break;
      case 2:
        break;

      default:
        break;
    }
  }
} 