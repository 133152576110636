import { NgModule } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeDivisa'
})

export class PipeDivisaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let valorDivisa: number = 0;
    if(parseFloat(value) > 0 && args.divisa == 'PESO'){
        valorDivisa = (value * parseFloat(args.trm));
    } else if(args.divisa == 'USD'){
      valorDivisa = (value / parseFloat(args.trm));
    }
    console.log("Precio ==> ", value);
    console.log("Objeto ==> ", args.divisa);
    console.log("Entro a pipe trm ==> ", args.trm);
    return this.numberToCurrency(valorDivisa);
  }

  numberToCurrency(value: any) {
    try {
      if (typeof value != 'string') {
        value = value.toString();
      }
      value = parseFloat(value).toFixed(2);
      value = value.toString().replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      return value;
    } catch (x) {
      console.log(x);      
      return value;
    }
  }
}

@NgModule({
  declarations: [
    PipeDivisaPipe
  ]
})
export class PipeDivisaPipeModule{}
