import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms'; //Import necesario para formularios
import { AjaxService } from '../../../../_services/ajax.service';
import { ReportUniversalComponent } from './report-universal.component';
import { FormsModule } from '@angular/forms'

// Tabla
import { DatatableModule } from '../../generalComponents/component-datatable/datatable.module';
import { DatatableComponent } from '../../generalComponents/component-datatable/datatable.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DatatableModule
  ],
  exports: [
    ReportUniversalComponent
  ],
  declarations: [
    ReportUniversalComponent
  ],
  providers: [
    AjaxService
  ],
  entryComponents: [DatatableComponent]
})
export class ReportUniversalModule { }
