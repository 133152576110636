import { Injectable } from '@angular/core';
import { Events } from './event';
import { AjaxService } from './ajax.service';


@Injectable()
export class SocketsService {
  ioConnection: any = null;

  constructor(private ajax: AjaxService) { }

  async startSocket(url_str: string) {
    let infoConnection: any = await this.ajax.getSocket(url_str);
    // console.log(infoConnection);
    if (infoConnection.status == 1) {
      // Recibo el nombre de la conexión desde el servidor
      let nameConnection = infoConnection.nameConnection;
      // Inicio la conexión del socket desde el frontend
      this.ajax.initSocket();
      // Activo la escucha en el frontEnd
      return nameConnection;
    } else {
      return false;
    }
  }

  workingSocket(nameConnection: any, callBack: any, continueExecute: boolean) {
    this.ioConnection = this.ajax.onMessage(nameConnection).subscribe((resultSocket: any) => {
      // console.log(resultSocket);
      callBack(resultSocket).then((resCallback: any) => {
        this.ajax.send(nameConnection, { status: continueExecute })
      })

      // setTimeout(() => {
      //   if (continueExecute) {
      //     console.log('Si salvo el socket? ');
      //     this.ajax.send(nameConnection, { status: true })
      //   }
      // }, 10000)
    })
  }


  validResponseLoad(result) {
    if (result.status == 1) {
      return true;
    } else {
      return false;
    }
  }

  cancelRequest(nameConnection: any) {
    // console.log('se emite un cancel ', nameConnection);
    this.ajax.send(nameConnection, { status: false });
    this.ajax.onEvent(Events.DISCONNECT)
  }
}
